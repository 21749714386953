import React, { Component } from 'react';
import {Label} from 'app/components/Basics/';
import './DemographicAnswersComponent.scss';
import {ApsResponseDownloadFormContext} from '../../ApsResponseDownloadContext'
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';

class DemographicAnswers extends Component {

    static contextType = ApsResponseDownloadFormContext;

    constructor(props){
        super(props);        
    }	
	
	demoQuestionCodeHandler(i, event) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.demographicFilter[i].questionId = event.target.value;
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
    }

	demoAnswerCodeHandler(i, event) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.demographicFilter[i].answerValues = event.target.value;
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
    }

	addDemoFilter() {		
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.demographicFilter.push({
			questionId: "", 
			answerValues: ""
		});
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

    render() {
        return (
            <div className='DemographicAnswersComponent'>
				<Label tooltipPosition="right" tooltip="Filter by demographics questions and answers">Demographics Answers <ButtonIcon onClick={this.addDemoFilter.bind(this)} icon={Icons.AddPlus}/></Label>
				
				{this.context.selectedSelectionFilters.demographicFilter && 
						this.context.selectedSelectionFilters.demographicFilter.length > 0 && (<div className='field-demo-answer' key={`demo_filter_label`}>
					<div className='demo-labels'><Label tooltipPosition="right" tooltip="Type the demographic question code">Question Code</Label></div>
					<div className='demo-labels'><Label tooltip="Type a comma separated list of answers values">Answer Values</Label></div>
				</div>)}

				{this.context.selectedSelectionFilters.demographicFilter && 
						this.context.selectedSelectionFilters.demographicFilter.map((demo, i) => {
							return (<div className='field-demo-answer' key={`demo_filter_${i}`}>
								<EcoInput 
									className="question-code"									
									value={demo.questionId} 
									onChange={this.demoQuestionCodeHandler.bind(this, i)}
									key={`question-code_${i}`}
									id={`question-code_${i}`}	
									type="number"								
									>							
								</EcoInput>	
								<EcoInput 									
									value={demo.answerValues} 
									onChange={this.demoAnswerCodeHandler.bind(this, i)}
									key={`answer_values_${i}`}
									id={`answer_values_${i}`}									
									>							
								</EcoInput>
							</div>)
					})}
			</div>
        );
    }
}

DemographicAnswers.contextType = ApsResponseDownloadFormContext;
export default DemographicAnswers;