import React, { Component } from 'react';
import Select from 'react-select'
import './multiSelect.scss';
import {Badge} from '@ecosystem/ui-lib/core/components/Badge';
import {Button} from 'app/components/Basics/'
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';

class MultiSelect extends Component {
    constructor(props){
        super(props);

        this.state = {
			selectedValues: this.props.selectedOptions
        }
    }

	handleChange = (selectedOptions) => {		
		this.setState({ selectedValues: selectedOptions });
		this.props.onChange(selectedOptions);
	}

	showSelectedOptions() {
		this.props.selectedOptions.forEach((selectedOption) => {
			return <div>{selectedOption.value}</div>
		});
	}

	removeSelectedOption(option) {		
		var selectedValues = this.state.selectedValues;
		selectedValues = selectedValues.filter(function(f) { return f.value !== option.value })		
		this.setState({ selectedValues: selectedValues });
		this.props.onRemove(option);
	}

	badgeContent(option) {
		return (<div className='badge-content'>
					{option.label}
					<Button disabled={this.props.isDisabled} title="remove" onClick={()=> this.removeSelectedOption(option)} simple small icon={Icons.Close} />
				</div>)
	}

	selectAll() {
		this.setState({ selectedValues: this.props.options });
		this.props.onChange(this.props.options);
	}

	unselectAll() {
		this.setState({ selectedValues: [] });
		this.props.onChange([]);
	}

    render() {
		let menuPlament = "bottom";
		if (this.props.menuPlament && this.props.menuPlament==="top") {
			menuPlament = this.props.menuPlament;
		}
		
        return (
            <div className="multi-select-component">				
                <Select 
				 	classNamePrefix='multi-select-box'
					closeMenuOnSelect={false}
					value={this.state.selectedValues}
					onChange={this.handleChange}
					options={this.props.options} 
					isMulti={true} 
					controlShouldRenderValue={false}
					isClearable={false}
					backspaceRemovesValue={false}
					isDisabled={this.props.isDisabled}
					menuPlacement={menuPlament}
					>
				</Select>
				{this.props.showSelectAll && (<div> 
					<div className="form-row-left">
						<Button title="Select All" onClick={()=> this.selectAll()} simple small>Select All</Button>
						<Button title="Clear All" onClick={()=> this.unselectAll()} simple small>Clear All</Button>					
					</div>					
				</div>)}

				<div className='selected-list'>					
					{this.props.selectedOptions && this.props.selectedOptions.map((option, i) => {
						if (option == null) return null;
						return (<Badge key={option.value} text={this.badgeContent(option)} className="selected-item-badge"></Badge> )
					})}
				</div>
            </div>
        );
    }
}
export default MultiSelect;