function ValidateApsResponseDownloadSet(selectedApsResponseDownloadSet) {
	var validationErrors = [];	
	if (!selectedApsResponseDownloadSet.startDate || selectedApsResponseDownloadSet.startDate === '') {
		addErrorMessage(validationErrors, 'startDate', 'Start date is empty');
	}
	if (!selectedApsResponseDownloadSet.endDate || selectedApsResponseDownloadSet.endDate === '') {
		addErrorMessage(validationErrors, 'startDate', 'End date is empty');
	}	
	// if (!selectedApsResponseDownloadSet.questions || selectedApsResponseDownloadSet.questions.length === 0) {
	// 	addErrorMessage(validationErrors, 'questions', 'Questions cannot be empty');
	// }	
	
	return validationErrors;
}

function addErrorMessage(validationErrors, field, message) {      
	validationErrors.push({ field: field, errorMessage: message});      
}

export default ValidateApsResponseDownloadSet;