function NewSubSurvey() {
    return {
		id: null,
		name: "",
		startDate: null,
		endDate: null,
		sequence: null,
		diaryTimeInterval: "00:15:00",
		MediaTypes: []
      }
}
export default NewSubSurvey;
