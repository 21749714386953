
import React, { Component } from 'react';
import ReportDownloadService from "../../services/reportdownload.service";
import ReportDownloadSelectionService from "../../services/reportDownloadSelection.service";
import ReportDownloadForm from './ReportDownloadForm';
import  GetErrorMessage  from '../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../components/toast"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import './ReportDownloadComponent.scss';

class ReportDownload extends Component {
	constructor (props) {
	super(props);
		this.state = {			
			loading: false,
			errorMessage: null,
			selectedSelectionFilters: null,			
			uiBlocked: false,
			mySavedFilters: [],
			metadata: {
				regions: []
			}		
		}		
	}

	componentDidMount() {		
		this.queryMetadata();
		this.queryMySelectionFilters();
	}

	
	queryMySelectionFilters() {		
		ReportDownloadSelectionService.find()
				.then(response =>{					
					this.setState({
						mySavedFilters: response.data					
					});
					return response;          
				})
				.catch(error => {			  
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	queryMetadata() {		
		this.toggleUiBlock(true);
		ReportDownloadService.getMetadata()
				.then(response => {
					this.toggleUiBlock(false);
					this.setState({
						metadata: response.data					
					});
					return response;          
				})
				.catch(error => {			  
					this.toggleUiBlock(false);
					let errorMessage = GetErrorMessage(error);					
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	queryReportDownload(term) {
		this.setState({ loading: true, filteredData: [] });
		const _this = this;
		ReportDownloadService.find(term)
			.then(response =>{				
				this.setState({ filteredData: response.data,
					allData:  response.data,					
					loading: false				
				}, function() { this.filterReportDownloads(this.state.search); });
				return response;          
			})
			.catch(error => {			  
				let errorMessage = GetErrorMessage(error);
				console.log("errorMessage on catch");
				console.log(errorMessage);
				this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}	

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	}

	updateSelectedReportDownloadFilters(selectedSelectionFilters) {		
		this.setState({selectedSelectionFilters: selectedSelectionFilters})
	}
	
    render() {

        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">

				<div className="app-content scrollbox">
					<ReportDownloadForm
						toggleUiBlock={this.toggleUiBlock.bind(this)} 
						selectedSelectionFilters={this.state.selectedSelectionFilters}
						updateSelectedSelectionFilters={this.updateSelectedReportDownloadFilters.bind(this)}
						metadata={this.state.metadata}
						mySavedFilters={this.state.mySavedFilters}
						queryMySelectionFilters={this.queryMySelectionFilters.bind(this)}
					/>					
				</div>
				{this.state.successMessageFolder && ( <ShowSuccess clearMessage={() => this.setState({successMessageFolder: ''})} message={this.state.successMessageFolder} /> )}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}				
			</main>
			</BlockUi>
        );
    }
}

export default ReportDownload;