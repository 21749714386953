import React, { Component } from 'react';
import {Label, DatePickerCalendar, Switch} from 'app/components/Basics/';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import './FiltersComponent.scss';
import {ApsResponseDownloadFormContext} from '../../ApsResponseDownloadContext'
import MultiSelect from 'app/components/MultiSelect'
import MultiFreeText from 'app/components/MultiFreeText'
import DemographicAnswers from '../DemographicAnswers/DemographicAnswersComponent';

class Filters extends Component {

    static contextType = ApsResponseDownloadFormContext;	
    constructor(props){
        super(props);
		this.state = {
            reportFormats: [
				{value:"spss", label: "SPSS"},
				{value:"json", label: "JSON"}
			  ]            
        }		
    }

	setStartDate(date) {		
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.startDate = date;
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	setEndDate(date) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.endDate = date;
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	changeStatusHandler(selectedItem) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.status = parseInt(selectedItem.value);		
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
    }

	getSelectedStatusOption() {
        return this.props.metadata.statuses?.find(t=> parseInt(t.value) === this.context.selectedSelectionFilters.status);
    }

	createOption(item, metadataList) {	
		if (!metadataList) { return null }
		var metadataItem = metadataList.find(m=>m.value === item);
		if (!metadataItem) { return null }

		return {
			value: item,
			label: metadataItem.label
		}
	}	

	getSelectedOptionsMultiSelect(metadata, selectedValues ) {
		var result = [];
		if (selectedValues == null) return result;
		
		result = selectedValues.map(
			item => this.createOption(item, metadata)
		);		
		return result;
	}
	
	onChangeQuestion(items)  {		
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.questions = [];
		selectedSelectionFilters.questions = items.map(x => x.value);		
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);       
	}

	onRemoveQuestion(item)  {		
		var selectedSelectionFilters = this.context.selectedSelectionFilters;		
		selectedSelectionFilters.questions = selectedSelectionFilters.questions.filter(function(f) { return f !== item.value });
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	onChangeChannel(items)  {		
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.channelIds = [];
		selectedSelectionFilters.channelIds = items.map(x => x.value);		
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);       
	}

	onRemoveChannel(item)  {		
		var selectedSelectionFilters = this.context.selectedSelectionFilters;		
		selectedSelectionFilters.channelIds = selectedSelectionFilters.channelIds.filter(function(f) { return f !== item.value });
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}	

	addNewProgramTitle(newText) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;        
		selectedSelectionFilters.programTitles.unshift(newText);
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	removeProgramTitle(index) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;        
		selectedSelectionFilters.programTitles.splice(index, 1);
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	addNewProgramId(newText) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;        
		selectedSelectionFilters.programIds.unshift(newText);
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	removeProgramId(index) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.programIds.splice(index, 1);
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	minexSwitchHandler() {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.includeMinexes = !this.context.selectedSelectionFilters.includeMinexes;
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	genericSwitchHandler() {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.includeGenerics = !this.context.selectedSelectionFilters.includeGenerics;
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	subSurveySwitchHandler() {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.includeSubsurveys = !this.context.selectedSelectionFilters.includeSubsurveys;
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
	}

	changeSubSurveyHandler(selectedItem) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.subsurveyId = parseInt(selectedItem.value);		
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
    }

	getSelectedSubSurveyOption() {
        return this.props.metadata.subSurveys?.find(t=> parseInt(t.value) === this.context.selectedSelectionFilters.subsurveyId);
    }
	
	changeQuestionSetHandler(selectedItem) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.questionSetId = selectedItem.value;
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
    }

	getSelectedQuestionSetOption() {
        return this.props.metadata.questionSets?.find(t=> t.value === this.context.selectedSelectionFilters.questionSetId);
    }

	changeReportFormatHandler(selectedItem) {
		var selectedSelectionFilters = this.context.selectedSelectionFilters;
		selectedSelectionFilters.isSpssFormat = selectedItem.value === "spss";
		this.context.updateSelectedSelectionFilters(selectedSelectionFilters);
    }

	getSpssOption() {
		return this.state.reportFormats?.find(t=> t.value === "spss");
	}

	getSelectedReportFormatOption() {
		if (this.context.selectedSelectionFilters.isSpssFormat === false) {
        	return this.state.reportFormats?.find(t=> t.value === "json");
		}
		else {
			return this.getSpssOption();
		}
    }

    render() {		
        return (
            <div>
				<div className="FiltersComponent form-row">
					<div className="form-row-left">
						<div className="field-spss">
							<Label>Download Format</Label>
							<Select name="reportformat" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}                            
								onChange={val=>{
									this.changeReportFormatHandler(val)                                
								}}							
								options={this.state.reportFormats}
								value={this.getSelectedReportFormatOption()}
								defaultValue={this.getSpssOption()}
								key={this.context.selectedSelectionFilters.isSpssFormat}
								>							
							</Select>
						</div>
						<div className="field-survey-from">
							<Label>Date From</Label>
							<DatePickerCalendar value={this.context.selectedSelectionFilters.startDate} onChange={this.setStartDate.bind(this)} />
						</div>
						<div className="field-survey-to">
							<Label>Date To</Label>
							<DatePickerCalendar value={this.context.selectedSelectionFilters.endDate} minDate={this.context.selectedSelectionFilters.startDate} onChange={this.setEndDate.bind(this)} />
						</div>
						<div className="field-status">
							<Label>Status</Label>
							<Select name="status" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}                            
								onChange={val=>{
									this.changeStatusHandler(val)                                
								}}							
								options={this.props.metadata.statuses}
								value={this.getSelectedStatusOption()}
								key={this.context.selectedSelectionFilters.status}
								>							
							</Select>
						</div>			
					</div>
					<div className="form-row-right">
					</div>
            	</div>
				<div className="FiltersComponent form-row">
					<div className="form-row-left align-top">
						<div className='field-program-titles'>
							<Label tooltip="Select the Program Titles that apply for this download" tooltipPosition="right">Program Titles</Label>							
							<MultiFreeText values={this.context.selectedSelectionFilters.programTitles}
									disabled={this.context.selectedSelectionFilters.programIds?.length > 0}
									addNewTextHandler={this.addNewProgramTitle.bind(this)}
									removeTextHandler={this.removeProgramTitle.bind(this)}>									
							</MultiFreeText>
						</div>
						<div className='field-program-ids'>
							<Label tooltip="Select the Program Ids that apply for this download">Program Ids</Label>							
							<MultiFreeText values={this.context.selectedSelectionFilters.programIds}
									disabled={this.context.selectedSelectionFilters.programTitles?.length > 0}
									addNewTextHandler={this.addNewProgramId.bind(this)}
									removeTextHandler={this.removeProgramId.bind(this)}>									
							</MultiFreeText>
						</div>
						<div className='field-channels'>
							<Label tooltip="Select the channels that apply for this download">Channels</Label>							
							<MultiSelect 
								selectedOptions={this.getSelectedOptionsMultiSelect(this.props.metadata.channels, this.context.selectedSelectionFilters.channelIds)} 
								onChange={this.onChangeChannel.bind(this)} 
								onRemove={this.onRemoveChannel.bind(this)} 
								options={this.props.metadata.channels}
								showSelectAll={true}>
							</MultiSelect>
						</div>						
					</div>					
            	</div>
				<div className="FiltersComponent form-row">
					<div className="form-row-left align-top">						
						<div className="field-demo">							
							<DemographicAnswers/>
						</div>
					</div>					
            	</div>
				<div className="FiltersComponent form-row">
					<div className="form-row-left align-top">						
						<div className="field-enabled">
							<Label>Include Minexes</Label>
							<div className="default-height centered"><Switch name="include-minex" setSwitchValue={this.minexSwitchHandler.bind(this)} checked={this.context.selectedSelectionFilters.includeMinexes === true}/></div>
						</div>
						<div className="field-enabled">
							<Label>Include Generics</Label>
							<div className="default-height centered"><Switch name="include-generics" setSwitchValue={this.genericSwitchHandler.bind(this)} checked={this.context.selectedSelectionFilters.includeGenerics === true}/></div>
						</div>
						<div className="field-enabled">
							<Label>Include SubSurvey</Label>
							<div className="default-height centered"><Switch name="include-subsurvey" setSwitchValue={this.subSurveySwitchHandler.bind(this)} checked={this.context.selectedSelectionFilters.includeSubsurveys === true}/></div>
						</div>
						<div className="field-subsurvey">
							<Label>SubSurvey</Label>
							<Select name="subsurvey" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}                            
								onChange={val=>{
									this.changeSubSurveyHandler(val)                                
								}}							
								options={this.props.metadata.subSurveys}
								value={this.getSelectedSubSurveyOption()}
								key={this.context.selectedSelectionFilters.subsurveyId}
								>							
							</Select>
						</div>
					</div>					
            	</div>
				<div className="FiltersComponent form-row">
					<div className="form-row-left">
						<div className='questions-selection'>
							<Label tooltipPosition="right" tooltip="Select the question codes that apply for this csv download">Questions</Label>							
							<MultiSelect 
								selectedOptions={this.getSelectedOptionsMultiSelect(this.props.metadata.questions, this.context.selectedSelectionFilters.questions)} 
								onChange={this.onChangeQuestion.bind(this)} 
								onRemove={this.onRemoveQuestion.bind(this)} 
								options={this.props.metadata.questions}
								showSelectAll={true}
								menuPlament="top">
							</MultiSelect>
						</div>				
					</div>					
            	</div>
				<div className="FiltersComponent form-row">
					<div className="form-row-left">
						<div className='questions-selection'>
							<Label>Question Set</Label>
							<Select name="questionSet" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}                            
								onChange={val=>{
									this.changeQuestionSetHandler(val)                                
								}}							
								options={this.props.metadata.questionSets}
								value={this.getSelectedQuestionSetOption()}
								key={this.context.selectedSelectionFilters.questionSetId}
								>							
							</Select>
						</div>				
					</div>					
            	</div>
			</div>
        );
    }
}

Filters.contextType = ApsResponseDownloadFormContext;

export default Filters;