import React, { Component } from 'react';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import {Button} from 'app/components/Basics/'
import { ReactComponent as PlusIcon } from 'app/assets/icons/Plus.svg';
import './SubSurveysTableComponent.scss';
import { DateTime } from "luxon";
import Modal from 'app/components/Modal';
import SubSurveyForm from '../SubSurveysForm/SubSurveyFormComponent'
import { ReactComponent as EditPen } from 'app/assets/icons/edit-pen.svg';
import {SubSurveyContext} from '../../SubSurveyContext'
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import NewSubSurvey from '../../models/SubSurveyModel';

class SubSurveysTable extends Component {

    constructor(props){
        super(props);
        this.state = {            
            showSubSurveyFormModal: false,
			selectedSubSurvey: null
        }        
    }	

	formatMediaTypeSting(mediaType) {		
		return mediaType.label + " (" + mediaType.value + ")";
	}	

	getMediaTypes(mediaTypes) {
		return mediaTypes.map(this.formatMediaTypeSting).join(', ');
	}

	getTemplateIdAndVersion(subSurvey) {
		if (subSurvey.nqTemplateId == null || subSurvey.nqTemplateId === "") return "";		
		return subSurvey.nqTemplateId + " v" + subSurvey.nqTemplateVersion;
	}

	addSubSurveyRow(subSurvey) {        
        const cols = [];
		var formatedStartDate = "";
		var formatedEndDate = "";
		if (subSurvey.startDate) {
			const startDate = DateTime.fromISO(subSurvey.startDate);
			formatedStartDate = startDate.toFormat("dd/MM/yyyy");
		}
		if (subSurvey.endDate) {
			const endDate = DateTime.fromISO(subSurvey.endDate);
			formatedEndDate = endDate.toFormat("dd/MM/yyyy");
		}
		
		cols.push(<GenericCell className="column-edit" primary={<EditPen className='edit-icon' onClick={(e) => {this.openFormModal(e, subSurvey, true)}}/>}/>);
        cols.push(<GenericCell className="column-name" primary={<div className="subSurvey-name">{subSurvey.name}</div>}/>);
		cols.push(<GenericCell className="column-sequence" primary={subSurvey.sequence}/>);
        cols.push(<GenericCell className="column-nqtemplate-id" primary={this.getTemplateIdAndVersion(subSurvey)} />);		
		cols.push(<GenericCell className="column-start-date" primary={formatedStartDate}/>);
		cols.push(<GenericCell className="column-end-date" primary={formatedEndDate}/>);		
		cols.push(<GenericCell className="column-time-interval" primary={subSurvey.diaryTimeInterval}/>);
		cols.push(<GenericCell className="column-time-media-types" primary={this.getMediaTypes(subSurvey.mediaTypes)}/>);
        return cols;
    }
	
	getSelectedSurveyPhaseOption(surveyPhaseId) {		
        return this.props.metadata.surveyPhases.find(t=> parseInt(t.value) === surveyPhaseId);
    }

	closeFormModal() {
		this.setState({
			showSubSurveyFormModal: false,
			selectedSubSurvey: null,
			originalSelectedSubSurvey: null,
			editExistingSubSurvey: null
		});
	}

	openFormModal(event, subSurvey, editExistingSubSurvey) {		
		let originalSubSurvey = cloneDeep(subSurvey);
		this.setState({
			showSubSurveyFormModal: true,
			editExistingSubSurvey: editExistingSubSurvey,
			selectedSubSurvey: cloneDeep(subSurvey),
			originalSelectedSubSurvey: originalSubSurvey
		});
	}

	saveSubSurvey() {		
		let action = this.state.editExistingSubSurvey === true ? "update" : "create"
		let result = this.context.saveSubSurvey(this.state.selectedSubSurvey, action);		
		result.then(success => {
			if (success) {
				this.closeFormModal();
			}
		});
	}

	disableSave() {
		if (!this.context.canChangeData) return true;		
		return isEqual(this.state.selectedSubSurvey, this.state.originalSelectedSubSurvey);		
	}

    render() {
		const rows = []; 
		if (this.props.subSurveys) {   
			this.props.subSurveys.forEach((subSurvey) => {
				rows.push(this.addSubSurveyRow(subSurvey));
			});
		}

		return (
            <div className="AppFormComponent SubSurveyTable">		
				<div className="form-row">
					<div className="form-row-left">							
					</div>
					<div className="form-row-right">
						<Button disabled={!this.context.canChangeData} secondary icon={<PlusIcon/>} onClick={() => this.openFormModal("", new NewSubSurvey(), false)}>Sub-Survey</Button>
					</div>
                </div>		
				<Table
					className="SubSurveyList"
					columns={[
						{
							headerKey: `column-edit`,
							className: "AppFormTableHeader column-edit",
							name: ``
						},
						{   headerKey: `column-name`,
							className: "AppFormTableHeader column-name",
							name: `Name`
						},
						{   headerKey: `column-sequence`,
							className: "AppFormTableHeader column-sequence",
							name: `Sequence`
						},
						{
							headerKey: `column-nqtemplate-id`,
							className: "AppFormTableHeader column-nqtemplate-id",
							name: `NQ Template Id`
						},						
						{
							headerKey: `column-start-date`,
							className: "AppFormTableHeader column-start-date",
							name: `Start Date`
						},									
						{
							headerKey: `column-end-date`,
							className: "AppFormTableHeader column-end-date",
							name: `End Date`
						},									
						{
							headerKey: `column-time-interval`,
							className: "AppFormTableHeader column-time-interval",
							name: `Time Interval`
						},									
						{
							headerKey: `column-media-types`,
							className: "AppFormTableHeader column-media-types",
							name: `Media Types`
						}
					]}
					rows= {rows}                
					sortingOptions={{headerKey: null}}
					isHeaderSticky={false}
					isFirstColumnSticky={false}
				/>
				{this.state.showSubSurveyFormModal && ( <Modal size="fit-content" content={
						<SubSurveyForm subSurvey={this.state.selectedSubSurvey} metadata={this.props.metadata} />
					}
					confirmButtonLabel="Save"
					disableConfirmButton={this.disableSave()}				
					confirmModalHandler={this.saveSubSurvey.bind(this)}				
					cancelButtonLabel="Cancel"
					cancelModalHandler={this.closeFormModal.bind(this)} 					
					/>)
				}							
			</div>
        );
    }
}
SubSurveysTable.contextType = SubSurveyContext;
export default SubSurveysTable;