import React, { Component } from 'react';
import { Label as EcoLabel } from '@ecosystem/ui-lib/core/components/Label';
import { Icon, Icons } from '@ecosystem/ui-lib/core/components/Icon';
import { Tooltip } from '@ecosystem/ui-lib/core/components/Tooltip';
import './LabelComponent.scss';

class Label extends Component {
    render() {
        let placement = "left";
        if (this.props.tooltipPosition != null && this.props.tooltipPosition !== '') {
            placement = this.props.tooltipPosition;
        }
        return (
            <div className={`LabelComponent ${this.props.className}`}>
            <EcoLabel text={this.props.children} className={this.props.className}></EcoLabel>
            { this.props.tooltip? (
                <Tooltip placement={placement} TooltipContent={() => (<div>{this.props.tooltip}</div>)}>
                    <Icon icon={Icons.HelpFill} size="16"></Icon>
                </Tooltip>) : ""
            }
            </div>
        );
    }
}

export default Label;