import React, { Component, useState } from 'react';
// import { GlobalControlLine, GlobalFieldLine, TabQuestion, TabOptions, TabInputText, TabInputNumeric, 
//     TabScale, TabLoop, TabPresentationSelect, TabPresentationRating, TabPresentationInput } from './';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { MemoryRouter,Switch,Route,Redirect,withRouter} from "react-router-dom";
import {TabBar} from 'app/components/Basics';
import './QuestionComponent.scss';
import { ReactComponent as DuplicateIcon } from 'app/assets/icons/Duplicate.svg';
import GlobalControlLine from './components/GlobalControlLine/GlobalControlLineComponent';
import GlobalFieldLine from './components/GlobalFieldLine/GlobalFieldLineComponent';
import TabQuestion from './components/TabQuestion/TabQuestionComponent';
import {TabOptions, CardOptionsAlternative} from './components/TabOptions/TabOptionsComponent';
import {TabInputText, TabInputNumeric} from './components/TabInput/TabInputComponent';
import TabScale from './components/TabScale/TabScaleComponent';
import TabRating from './components/TabRating/TabRatingComponent';
import TabLoop from './components/TabLoop/TabLoopComponent';
import {TabPresentationSelect, TabPresentationRating, TabPresentationInput, TabPresentationNumeric} from './components/TabPresentation/TabPresentationComponent';
import TabGrid from './components/TabGrid/TabGridComponent';
import {QuestionFormContext} from './QuestionContext'
import QuestionService from "../../services/question.service";
import  GetErrorMessage  from '../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../components/toast"
import NewQuestion from './QuestionModel';
import Modal from '../../components/Modal';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import ValidateQuestion from './QuestionValidation'
import { Constants } from '../../constants/constants'
import { questionCategories } from '../../constants/question-categories'

class QuestionForm extends Component {
    tabs = [];
    constructor(props){
        super(props);
        this.state = {           
            activeTab:'question',
            selectedQuestion: NewQuestion(),
            errors: []			
        }        
    }

    getTabs(selectedQuestion) {
        return [
            { id:"question", label: 'Question', url: 'question', disabled:false, error:this.tabQuestionHasValidationError()},
            { id:"options", label: 'Options', url: 'options', disabled: this.isOptionTabDisabled(selectedQuestion), error:this.tabOptionHasValidationError()},
            { id:"input", label: 'Input', url: 'input', disabled:!(selectedQuestion.questionType==="OPEN")},
			{ id:"inputNumeric", label: 'Input', url: 'inputNumeric', disabled:!(selectedQuestion.questionType==="NUMERIC"), error:this.tabInputNumericHasValidationError()},
			{ id:"rating", label: 'Rating', url: 'rating', disabled:!(selectedQuestion.questionType==="RATING"), error:this.tabInputNumericHasValidationError()},
            { id:"scale", label: 'Scale', url: 'scale', disabled:!(selectedQuestion.questionType==="SCALE")},
            { id:"grid", label: 'Statements', url: 'grid', disabled:!(selectedQuestion.questionType==="GGROUP"), error:this.tabGridHasValidationError()},			
            { id:"presentation", label: 'Presentation', url:'presentation', disabled: this.isPresentationTabDisabled(selectedQuestion)},
			{ id:"presentationNumeric", label: 'Presentation', url:'presentationNumeric', disabled: !(selectedQuestion.questionType==="NUMERIC"), error:this.tabInputNumericPresentationHasValidationError()},			
            { id:"loop", label: 'Loop', url: 'loop', disabled:true}
        ];
    }

    isOptionTabDisabled(selectedQuestion) {
        if (!selectedQuestion) return false;
        return selectedQuestion.questionType === "OPEN" 
		|| selectedQuestion.questionType === "NUMERIC"
		|| selectedQuestion.questionType === "RATING"
        || selectedQuestion.questionType === "INTRO";
    }

    isPresentationTabDisabled(selectedQuestion) {		
        if (!selectedQuestion) return true;
        return selectedQuestion.questionType.endsWith("OPEN") 
		|| selectedQuestion.questionType === "NUMERIC"
		|| selectedQuestion.questionType === "GGROUP"
        || selectedQuestion.questionType === "INTRO"
        || selectedQuestion.isSys !== questionCategories.Default;        
    }

    componentWillReceiveProps(nextProps) {
		if (nextProps.selectedQuestion != null) {
			this.setState({ 
				selectedQuestion: nextProps.selectedQuestion,
				activeTab: "question"
			});
		}

        if (!isEqual(this.props.selectedQuestion, nextProps.selectedQuestion)) {
            this.setState({errors: []});            
        }
    }

    updateSelectedQuestion(newSelectedQuestion) {        
        this.setState({ selectedQuestion: newSelectedQuestion});
    }

	getUpdatedQuestion(questionId) {
		this.props.toggleUiBlock(true);
		QuestionService.getQuestionById(questionId)
			.then(response => {				
				const updatedQuestion = response.data;
				this.props.updateSavedQuestionOnList(updatedQuestion);
				this.props.toggleUiBlock(false);
			})
			.catch(error => {
                let errorMessage = GetErrorMessage(error);
                console.log("errorMessage on catch");
                console.log(errorMessage);
                this.props.toggleUiBlock(false);
                this.setState({ errorMessage: errorMessage});
            });       
	}

    saveQuestion(question) {                
        this.props.toggleUiBlock(true);
        if (question.id) {
            QuestionService.UpdateQuestion(question)
                .then(response =>{                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The question was saved successfully' });
					this.getUpdatedQuestion(question.id);                    
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }
        else {            
            QuestionService.CreateQuestion(question)
                .then(response => {                    
                    const newQuestion = response.data;                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The question was created successfully' });                    
                    this.props.updateSavedQuestionOnList(newQuestion);                    
                    this.setState({selectedQuestion: newQuestion});
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }

    }
    
	deleteQuestion = event => {   
		if (this.props.canChangeData) {     
        	this.setState({ deleteQuestionModalText: `Are you sure you want to delete the question with code '${this.state.selectedQuestion.code}'?`});
		}
    }

    duplicateQuestion = event => {        
        let clonedQuestion = cloneDeep(this.state.selectedQuestion);
        clonedQuestion.id = null;
		clonedQuestion._frontEndQuestionId = null;
        clonedQuestion.code = `${clonedQuestion.code}_copy`;
		if (clonedQuestion.gridQuestions != null) {
			for (let index = 0; index < clonedQuestion.gridQuestions.length; index++) {				
				let gridQuestion = clonedQuestion.gridQuestions[index];
				gridQuestion.id = null;
				gridQuestion._frontEndQuestionId = Constants.TEMP_ID_PLACEHOLDER + Date.now() + "_" + index;				
				gridQuestion.code = `${gridQuestion.code}_copy`;
			}
		}
        this.setState({selectedQuestion: clonedQuestion});
		this.props.updateSelectedQuestion(clonedQuestion);
    }	

    confirmDelete() {        
        this.hideDeleteModal();
        this.props.toggleUiBlock(true);
        const questionId = this.state.selectedQuestion.id;
        QuestionService.DeleteQuestion(this.state.selectedQuestion)
        .then(response =>{
            this.props.toggleUiBlock(false);
            this.setState({successMessage: 'The question was deleted successfully' });            
            this.props.removeDeletedQuestion(questionId);            
            this.props.closeEditor();
        })
        .catch(error => {
            let errorMessage = GetErrorMessage(error);
            console.log("errorMessage on catch");
            console.log(errorMessage);
            this.props.toggleUiBlock(false);
            this.setState({ errorMessage: errorMessage});
        });
    }

    hideDeleteModal() {
        this.setState({deleteQuestionModalText: ''})
    }

    questionTypeChange() {
        this.setState({ activeTab: "question"});
    }	

	tabOptionHasValidationError() {		
		let validationError = this.state.errors.find(e => e.field.startsWith('option'));
		return validationError != null ? true : false;
	}

	tabQuestionHasValidationError() {		
		let validationError = this.state.errors.find(e => e.field === 'text');
		return validationError != null ? true : false;
	}

	tabInputNumericHasValidationError() {
		if (this.tabOptionHasValidationError()) return true;
		let validationError = this.state.errors.find(e => e.field.startsWith('numeric'));		
		return validationError != null ? true : false;
	}

	tabInputNumericPresentationHasValidationError() {		
		let validationError = this.state.errors.find(e => e.field.startsWith('scale'));		
		return validationError != null ? true : false;
	}
	
	tabGridHasValidationError() {		
		let validationError = this.state.errors.find(e => e.field.startsWith('gridQuestion'));
		return validationError != null ? true : false;
	}

	adjustGridAnswerOptions() {		
		let selectedQuestion = this.state.selectedQuestion;
		var gridOptions = cloneDeep(selectedQuestion.options);
		selectedQuestion.gridQuestions.forEach(gridQuestion => {
			gridQuestion.options = gridOptions;
		});
		this.updateSelectedQuestion(selectedQuestion);
	}

    handleSubmitAction = event => {
		if (this.props.canChangeData) {
			this.setState({errors: []});        

			if (this.formEl.checkValidity()) {            
				try {
					var validationErrors = ValidateQuestion(this.state.selectedQuestion);
					this.setState({errors: validationErrors});

					if (validationErrors && validationErrors.length === 0) {
						if (this.state.selectedQuestion.questionType === "GGROUP") {
							this.adjustGridAnswerOptions();
						}     
						this.saveQuestion(this.state.selectedQuestion);
					}
					
				} catch (e) {
					console.log("catch form validation");
					console.log(e);
				}

				event.preventDefault();
			}
		}
    }
    render() {
        this.tabs = this.getTabs(this.state.selectedQuestion);
        return (        
               
            <div className="AppFormComponent QuestionForm">                 
                <QuestionFormContext.Provider value={{updateSelectedQuestion: this.updateSelectedQuestion.bind(this), 
					selectedQuestion: this.state.selectedQuestion,
					canChangeData: this.props.canChangeData,
                    canChangeDemoQuestion: this.props.canChangeDemoQuestion,
					toggleUiBlock: this.props.toggleUiBlock}}>                
                    <form ref={ form => this.formEl = form } onSubmit={ this.handleSubmitAction }>
						
                        <div className="form-head">
                            <GlobalControlLine errors={this.state.errors} 
                                handleSubmitAction={this.handleSubmitAction.bind(this)} 
                                deleteQuestion={this.deleteQuestion.bind(this)}
                                duplicateQuestion={this.duplicateQuestion.bind(this)}
                                closeEditorButtonOnClick={this.props.closeEditorButtonOnClick}
								didSelectedQuestionChange={this.props.didSelectedQuestionChange}/>
                            <GlobalFieldLine questionTypeChange={this.questionTypeChange.bind(this)}/>
                        </div>
                        <div className="form-sections">
                            <MemoryRouter>
                                <div className="tab-bar">
                                    <TabBar key="QuestionForm" activeTab={this.state.activeTab} tabs={this.tabs} onTabActivate={item=>this.setState({activeTab:item.id})}/>
                                    {/* <div title="Load the current tab settings from another question." className="duplicate-tab"><ButtonIcon icon={DuplicateIcon}/></div> */}
                                </div>
                                <div className="section-content">
                                    <Switch>
                                        <Route exact path="/"><Redirect to={this.state.activeTab}/></Route>
                                        <Route exact path="/question"><TabQuestion errors={this.state.errors} /></Route>
                                        <Route exact path="/options"><TabOptions errors={this.state.errors} isMulti={this.state.qType==="MULTI"}/></Route>
                                        <Route exact path="/inputNumeric"><TabInputNumeric errors={this.state.errors}/></Route>
										<Route exact path="/presentationNumeric"><TabPresentationNumeric errors={this.state.errors}/></Route>										
                                        <Route exact path="/input"><TabInputText/></Route>
										<Route exact path="/rating"><TabRating/></Route>
                                        <Route exact path="/scale" component={TabScale}/>
										<Route exact path="/grid"><TabGrid hasError={this.tabGridHasValidationError()} /></Route>
										<Route exact path="/loop" component={TabLoop}/>
                                        {/* <Route exact path="/presentation">
                                            {this.state.qType==="SINGLE" || this.state.qType==="MULTI" ? <TabPresentationSelect/> : 
                                                this.state.qType==="SCALE" ? <TabPresentationRating/> : 
                                                this.state.qType==="NUMBER" ? <TabPresentationInput/> : ""
                                            }
                                        </Route> */}
                                        <Route exact path="/presentation"><TabPresentationSelect/></Route>
                                    </Switch>
                                    <Redirect to={this.state.activeTab}/>
                                </div>
                            </MemoryRouter>
                        </div>						
                    </form>                    
                </QuestionFormContext.Provider>

                {this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
                {this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}
                {this.state.deleteQuestionModalText && ( <Modal size="small" content={this.state.deleteQuestionModalText} confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
            </div>            
        );
    }
}
export default withRouter(QuestionForm); 