import React, { Component } from 'react';
import {Label, Button} from 'app/components/Basics/';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import './MySavedFilterComponent.scss';
import { ReactComponent as IconUp } from 'app/assets/icons/chevron_up.svg';
import { ReactComponent as IconDown } from 'app/assets/icons/chevron_down.svg';

class MySavedFilter extends Component {    

    constructor(props){
        super(props);   
		this.state = {            
            showMySelections: false,
			isEditMode: false        
        }             
    }

	toggleMySelections() {
        this.setState({showMySelections: !this.state.showMySelections});
    }	

	clearSelectedFilters() {
		this.props.updateSelectedMySavedFilter(this.props.NewMySavedFilter());
		this.props.updateSelectedSelectionFilters(this.props.NewSelectionFilters());
	}

	getMySavedFilters() {		
		var savedFilters = this.props.mySavedFilters.map(function (obj) {			
			return {value: obj.id, label: obj.name}
		});
		savedFilters.unshift({value: null, label: ""})
		return savedFilters;
	}

    changeMySavedFilterHandler(selectedItem) {
		if (selectedItem.value == null) {
			this.clearSelectedFilters();
		}
		else {
			var selectedSelectionFilters = this.props.mySavedFilters.find(c=>c.id === selectedItem.value);
			this.props.updateSelectedSelectionFilters(selectedSelectionFilters.selectionFilters);
			this.props.updateSelectedMySavedFilter(selectedSelectionFilters);
		}
    }

    getSelectedMySavedFilterOption() {		
        if (this.props.selectedMySavedFilter.id == null) return {};
        return this.getMySavedFilters()
			.find(t=> t.value === this.props.selectedMySavedFilter.id);
    }

	savedFilterNameHandler = event => {		
		var selectedMySavedFilter = this.props.selectedMySavedFilter;
		selectedMySavedFilter.name = event.target.value;
		this.props.updateSelectedMySavedFilter(selectedMySavedFilter);
    }

	getMySelectionButtonIcon() {
		return this.state.showMySelections ? <IconUp /> : <IconDown />;		
	}

	isDisabled() {
		if (!this.props.selectedSelectionFilters) {
			return true;
		}
		var errors = this.props.ValidateFilter(this.props.selectedSelectionFilters);
		if (errors?.length > 0) {
			return true;
		} 
		
		if (!this.props.selectedMySavedFilter.name || this.props.selectedMySavedFilter.name == '') {
			return true;
		}
	}

	handleCreateNewSelection = event => {        
		this.clearSelectedFilters();
		this.setState({isEditMode: true});
    }

	toggleEditMode() {
		this.setState({isEditMode: !this.state.isEditMode});
	}

	handleSaveFilters() {		
		this.props.handleSaveFilters();
		this.toggleEditMode();
	}

	handleDeleteFilters() {		
		this.props.handleDeleteFilters();
		this.toggleEditMode()
	}
	
	handleDeleteFilters() {		
		this.props.handleDeleteFilters();
		this.toggleEditMode()
	}

	handleCancelNewFilters() {
		this.props.queryMySelectionFilters();
		this.toggleEditMode();
		this.clearSelectedFilters();
	}

	isCreatingNewSelection() {
		return this.props.selectedMySavedFilter?.id == null && this.state.isEditMode;
	}

	getCancelButtonLabel() {
		return this.isCreatingNewSelection() ? 'Cancel New Selection' : 'Cancel Changes';
	}

    render() {
        return (
			<div className="MySavedFilterComponent">
				<div className="form-row">
					<div className="form-row-left mySelectionButton">
						<Button title="My Selections" small secondary icon={this.getMySelectionButtonIcon.bind(this)} onClick={this.toggleMySelections.bind(this)}>My Selections</Button>						
					</div>
					<div className="form-row-right">						
						{!this.state.showMySelections && (	
						<div>
							<Button className="clearAllButton" simple small title="Clear All Filters" onClick={this.handleCreateNewSelection}>Clear All Filters</Button>
						</div>
					)}
					</div>
					
					{this.state.showMySelections && (	
						<div className="form-row-right mySelectionButton">
							<Button simple small title="Create new selection" onClick={this.handleCreateNewSelection}> + Start New Selection</Button>
						</div>
					)}					
				</div>
				{this.state.showMySelections && (
				<div className="form-row mySelectionsBox">
					<div className="form-row-left selections-wrapper">
						<div className="field-my-selections">
							<Label>My Saved Selections</Label>
							<Select className='selectfilters' name="selectfilters" isSearchable={false} styles={{ option: styles => ({ minHeight: 40, ...styles }) }}            
								onChange={val=>{ this.changeMySavedFilterHandler(val)}}
								options={this.getMySavedFilters()}
								value={this.getSelectedMySavedFilterOption()}
								isDisabled={this.isCreatingNewSelection()}
								>
							</Select>
							{ this.props.selectedMySavedFilter?.id && (<>
								<Button small simple title="Change Selection" onClick={this.toggleEditMode.bind(this)}>Change Selection</Button>
							</>)}
						</div>
					</div>
					
					{ this.state.isEditMode && (<>
						<div className="form-row-left edit-mode">
							<div className="field-my-saved-name">
								<Label>Selection Name</Label>
								<EcoInput value={this.props.selectedMySavedFilter?.name} 
									onChange={this.savedFilterNameHandler}
									key={this.props.selectedMySavedFilter?.id}
									id="saved-filter-name"
									>							
								</EcoInput>	
								<div className='contol-buttons'>									
									<Button disabled={this.isDisabled()} small secondary title="Save selection filters" onClick={this.handleSaveFilters.bind(this)}>Save Selection</Button>
									<Button disabled={this.isDisabled()} small secondary title="Delete my saved selection filters" onClick={this.handleDeleteFilters.bind(this)}>Delete Selection</Button>
									<Button small secondary title="Cancel creation of new selection filters" onClick={this.handleCancelNewFilters.bind(this)}>{this.getCancelButtonLabel()}</Button>
								</div>				
							</div>
						</div>
										
					</>) 
					}			
				</div>
				)}
			</div>
         
        );
    }
}

export default MySavedFilter;