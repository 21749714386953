function NewSelectionFilters() {
    return {
        startDate: null,
        endDate: null,
        questions: [],        
        programTitles: [],
        programIds: [],
        channelIds: [],
        status: null,
        includeMinexes: true,
        includeGenerics: true,
        includeSubsurveys: true,
        demographicFilter: [],
        questionSets: [],
        isSpssFormat: true
      }
}
export default NewSelectionFilters;