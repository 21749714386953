import React, { Component } from 'react';
import './SubSurveyFormComponent.scss';
import { DateTime } from "luxon";
import {Label, DatePickerCalendar, NumericInput} from 'app/components/Basics/';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import {SubSurveyContext} from '../../SubSurveyContext'
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import MultiSelect from 'app/components/MultiSelect'

class SubSurveyForm extends Component {

    constructor(props){
        super(props);
        this.state = {
        }        
    }

	setStartDate(date) {		
		var subSurvey = this.props.subSurvey;
		subSurvey.startDate = date;		
		this.context.updateSelectedSubSurvey(subSurvey);
	}

	setEndDate(date) {		
		var subSurvey = this.props.subSurvey;
		subSurvey.endDate = date;		
		this.context.updateSelectedSubSurvey(subSurvey);
	}

	setName = event => {		
        var subSurvey = this.props.subSurvey;
		subSurvey.name = event.target.value ? event.target.value : "";
		this.context.updateSelectedSubSurvey(subSurvey);
    }

	setSequence = event => {
        var subSurvey = this.props.subSurvey;
        subSurvey.sequence = event.target.value !== '' ? parseInt(event.target.value) : '';
        this.context.updateSelectedSubSurvey(subSurvey);
    }

	setNqTemplateId = event => {
        var subSurvey = this.props.subSurvey;
        subSurvey.nqTemplateId = event.target.value !== '' ? parseInt(event.target.value) : '';
        this.context.updateSelectedSubSurvey(subSurvey);
    }

	setNqTemplateVersion = event => {
        var subSurvey = this.props.subSurvey;
        subSurvey.nqTemplateVersion = event.target.value !== '' ? parseInt(event.target.value) : '';
        this.context.updateSelectedSubSurvey(subSurvey);
    }

	setTimeInterval = event => {		
        var subSurvey = this.props.subSurvey;
		subSurvey.diaryTimeInterval = event.target.value ? event.target.value : "";
		this.context.updateSelectedSubSurvey(subSurvey);
    }

	onChangeMediaType(items)  {		
		var subSurvey = this.props.subSurvey;
		subSurvey.mediaTypes = [];
		subSurvey.mediaTypes = items;	
		this.context.updateSelectedSubSurvey(subSurvey);       
	}

	onRemoveMediaType(item)  {
		var subSurvey = this.props.subSurvey;
		subSurvey.mediaTypes = subSurvey.mediaTypes.filter(function(f) { return f.value !== item.value })
		this.context.updateSelectedSubSurvey(subSurvey);        
	}

	onChangeType(selectedItem) {
		var subSurvey = this.props.subSurvey;
		subSurvey.type = selectedItem.value;
		this.context.updateSelectedSubSurvey(subSurvey);
    }

	getSelectedOptionType() {		
        return this.props.metadata.subSurveyTypes.find(t=> t.value === this.props.subSurvey.type)
    }
	
    render() {
		return (
            <div className='AppFormComponent'>
				<div className="SubSurveyFormComponent form-row">
					<div className="form-row-left">
						<h4>Sub-Survey: <span className='orange'>{this.props.subSurvey.name}</span>  </h4>
					</div>
				</div>
				<div className="SubSurveyFormComponent form-row">
					<div className="form-row-left">		
						<div className="field-subsurvey-name">
							<Label>Name</Label>
							<EcoInput key={"subsurvey-name-id"} id="subsurvey-name-id" value={this.props.subSurvey.name} onChange={this.setName.bind(this)}></EcoInput>
						</div>					
						
						<div className="field-date-from">
							<Label>Date From</Label>
							<DatePickerCalendar disabled={!this.context.canChangeData} value={this.props.subSurvey.startDate} onChange={this.setStartDate.bind(this)} />
						</div>						
						<div className="field-date-from">
							<Label>Date To</Label>
							<DatePickerCalendar disabled={!this.context.canChangeData} value={this.props.subSurvey.endDate} onChange={this.setEndDate.bind(this)} />
						</div>
						{/* <div className="field-subsurvey-others">
							<Label>NQ Template Id</Label>
							<NumericInput value={this.props.subSurvey.nqTemplateId} onChange={this.setNqTemplateId.bind(this)}></NumericInput>
						</div>
						<div className="field-subsurvey-others">
							<Label>NQ Template Version</Label>
							<NumericInput value={this.props.subSurvey.nqTemplateVersion} onChange={this.setNqTemplateVersion.bind(this)}></NumericInput>
						</div> */}
						<div className="field-subsurvey-others">
							<Label>Sequence</Label>
							<NumericInput value={this.props.subSurvey.sequence} onChange={this.setSequence.bind(this)}></NumericInput>
						</div>
						<div className="field-subsurvey-others">
							<Label>Time Interval</Label>
							<EcoInput key={"subsurvey-time-interval"} id="subsurvey-time-interval" value={this.props.subSurvey.diaryTimeInterval} onChange={this.setTimeInterval.bind(this)}></EcoInput>
						</div>	
					</div>
				</div>
				<div className="SubSurveyFormComponent form-row">
					<div className="form-row-left align-top">
						<div className="field-subsurvey-types">
							<Label>Type</Label>
							<Select name="subsurveytype" isSearchable={false}
								styles={{ option: styles => ({ minHeight: 40, ...styles }) }}
								onChange={val=>{ this.onChangeType(val) }}
								options={this.props.metadata.subSurveyTypes}
								value={this.getSelectedOptionType()}>
							</Select>							
						</div>
						<div className="field-subsurvey-media-types">
							<Label>Media Types</Label>							
							<MultiSelect 
									selectedOptions={this.props.subSurvey.mediaTypes} 
									onChange={this.onChangeMediaType.bind(this)} 
									onRemove={this.onRemoveMediaType.bind(this)} 
									options={this.props.metadata.mediaTypes}>									
							</MultiSelect>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

SubSurveyForm.contextType = SubSurveyContext;
export default SubSurveyForm;