import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class ApsResponseDownloadService {  
  async download(responseDownload: any) {
    return await Api().post(`${API_URL}/apsRespondentResponse/download`, responseDownload, 
    { method: 'POST',
      headers:  authHeader() as any, 
      responseType: 'blob'  });      
  }

  async getMetadata() {
    return await Api().get(`${API_URL}/apsRespondentResponse/metadata`, { headers:  authHeader() as any });      
  }  
}

export default new ApsResponseDownloadService();