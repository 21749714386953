
import React, { Component } from 'react';
import ApsResponseDownloadService from "../../services/apsresponsedownload.service";
import ApsResponseSelectionService from "../../services/apsresponseSelection.service";
import ApsResponseDownloadForm from './ApsResponseDownloadForm';
import  GetErrorMessage  from '../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../components/toast"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import './ApsResponseDownloadComponent.scss';

class ApsResponseDownload extends Component {
	constructor (props) {
	super(props);
		this.state = {			
			loading: false,
			loadingMetaData: true,
			errorMessage: null,
			selectedSelectionFilters: null,			
			uiBlocked: false,
			mySavedFilters: [],
			metadata: {
				questions: [],
				regions: [],
				lastImportDate: ""
			}		
		}		
	}

	componentDidMount() {		
		this.queryMetadata();
		this.queryMySelectionFilters();
	}
	
	queryMySelectionFilters() {		
		ApsResponseSelectionService.find()
				.then(response =>{					
					this.setState({
						mySavedFilters: response.data					
					});
					return response;          
				})
				.catch(error => {			  
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}

	queryMetadata() {
		this.toggleUiBlock(true);
		ApsResponseDownloadService.getMetadata()
				.then(response =>{		
					console.log(response)			
					this.setState({
						metadata: response.data,
						loadingMetaData: false
					});
					this.toggleUiBlock(false);
					return response;          
				})
				.catch(error => {			  
					this.toggleUiBlock(false);
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.setState({ loading: false, errorMessage: errorMessage});            
			})
	}	

	toggleUiBlock(blockUi) {
		this.setState ({uiBlocked: blockUi});
	}

	updateSelectedApsResponseDownloadFilters(selectedSelectionFilters) {		
		this.setState({selectedSelectionFilters: selectedSelectionFilters})
	}
	
    render() {
        return (
			<BlockUi tag="div" blocking={this.state.uiBlocked}>  
			<main className="app-body">

				<div className="app-content scrollbox">
					{this.state.loadingMetaData === false && (<ApsResponseDownloadForm
						toggleUiBlock={this.toggleUiBlock.bind(this)} 
						selectedSelectionFilters={this.state.selectedSelectionFilters}
						updateSelectedSelectionFilters={this.updateSelectedApsResponseDownloadFilters.bind(this)}
						metadata={this.state.metadata}
						mySavedFilters={this.state.mySavedFilters}
						queryMySelectionFilters={this.queryMySelectionFilters.bind(this)}						
					/>)}
				</div>
				{this.state.successMessageFolder && ( <ShowSuccess clearMessage={() => this.setState({successMessageFolder: ''})} message={this.state.successMessageFolder} /> )}
				{this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}				
			</main>
			</BlockUi>
        );
    }
}

export default ApsResponseDownload;