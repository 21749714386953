import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import {ApsResponseDownloadFormContext} from './ApsResponseDownloadContext'
import ApsResponseDownloadService from "../../services/apsresponsedownload.service";
import ApsResponseSelectionService from "../../services/apsresponseSelection.service";
import GetErrorMessage  from '../../helpers/error-message'
import {ShowError, ShowSuccess} from "../../components/toast"
import fileDownload from 'js-file-download';
import ValidateApsResponseDownload from './ApsResponseDownloadValidation'
import MySavedFilter from '../../components/MySavedFilter/MySavedFilterComponent';
import Modal from 'app/components/Modal';
import NewSelectionFilters from './SelectionFiltersModel'
import NewMySavedFilter from './MySavedFiltersModel'
import { Button} from 'app/components/Basics/';
import Filters from './components/Filters/FiltersComponent';

class ApsResponseDownloadForm extends Component {    
    constructor(props){
        super(props);
        this.state = {
            showFiltersModal: false,            
            selectedSelectionFilters: NewSelectionFilters(),
            selectedMySavedFilter: NewMySavedFilter(),
            errors: [],
            deleteModalText: ''
        }        
    }

    cleanSelectedFilters() {
		this.setState({ 
            selectedSelectionFilters: NewSelectionFilters(), 
            selectedMySavedFilter: NewMySavedFilter()
        });
	}

    openFiltersModal() {
		this.setState({ showFiltersModal: true });
	}	

	closeFiltersModal() {		
		this.setState({ showFiltersModal: false });
	}    

    updateSelectedSelectionFilters(newselectedSelectionFilters) {
		this.setState({selectedSelectionFilters: newselectedSelectionFilters})
    }

    updateSelectedMySavedFilter(newSelectedMySavedFilter) {
		this.setState({selectedMySavedFilter: newSelectedMySavedFilter})
    }

    downloadResponses(apsResponseDownloadSet) {                
        this.props.toggleUiBlock(true);
        ApsResponseDownloadService.download(apsResponseDownloadSet)
            .then(response => {					
                let name = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
                let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];		
                let fileName = `${name}.${extension}`;
                fileDownload(response.data, fileName);
                this.setState({ successMessage: `The csv file ${fileName} was downloaded successfully`});
                this.props.toggleUiBlock(false);
                
            })
            .catch(error => {
                let errorMessage = GetErrorMessage(error);
                console.log("errorMessage on catch");
                console.log(errorMessage);
                this.props.toggleUiBlock(false);
                this.setState({ errorMessage: errorMessage});
            });
    }

    saveFilters(selectedMySavedFilter) {                
        this.props.toggleUiBlock(true);
        if (selectedMySavedFilter.id) {
            ApsResponseSelectionService.updateResponseSelection(selectedMySavedFilter)
                .then(response =>{                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The selection was saved successfully' });                    
                    this.props.queryMySelectionFilters();
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }
        else {            
            ApsResponseSelectionService.createResponseSelection(selectedMySavedFilter)
                .then(response => {					
                    const newSelectedMySavedFilter = response.data;                    
                    this.props.toggleUiBlock(false);
                    this.setState({successMessage: 'The selection was created successfully' });                                        
                    this.setState({selectedMySavedFilter: newSelectedMySavedFilter});					
                    this.props.queryMySelectionFilters();
					
                })
                .catch(error => {
					let errorMessage = GetErrorMessage(error);
					console.log("errorMessage on catch");
					console.log(errorMessage);
					this.props.toggleUiBlock(false);
					this.setState({ errorMessage: errorMessage});
                });
        }

    }    

    handleSubmitAction = event => {        
        var validationErrors = ValidateApsResponseDownload(this.state.selectedSelectionFilters);
        this.setState({errors: validationErrors});
        if (validationErrors && validationErrors.length === 0) {                    
            this.downloadResponses(this.state.selectedSelectionFilters);
        }
        return;
    }

    handleSaveFilters = event => {
        var validationErrors = ValidateApsResponseDownload(this.state.selectedSelectionFilters);
        this.setState({errors: validationErrors});
        if (validationErrors && validationErrors.length === 0) {
            var selectedMySavedFilter = this.state.selectedMySavedFilter
            selectedMySavedFilter.selectionFilters = this.state.selectedSelectionFilters;
            this.setState({ selectedMySavedFilter: selectedMySavedFilter });
            this.saveFilters(this.state.selectedMySavedFilter);
        }
        return;
    }    

    handleDeleteFilters = event => {
		//if (this.props.canChangeData) {    
        	this.setState({ deleteModalText: `Are you sure you want to delete the selection with name '${this.state.selectedMySavedFilter.name}'?`});
		//}
    }

    hideDeleteModal() {
        this.setState({deleteModalText: ''})
    }

    confirmDelete() {        
        this.hideDeleteModal();
        this.props.toggleUiBlock(true);        
        ApsResponseSelectionService.deleteResponseSelection(this.state.selectedMySavedFilter)
        .then(response =>{            
            this.setState({successMessage: 'The selection was deleted successfully' });            
            this.props.queryMySelectionFilters();
			setTimeout(() => {
                this.cleanSelectedFilters();
				this.props.toggleUiBlock(false);						
			}, 2000);

            
        })
        .catch(error => {
            let errorMessage = GetErrorMessage(error);
            console.log("errorMessage on catch");
            console.log(errorMessage);
            this.props.toggleUiBlock(false);
            this.setState({ errorMessage: errorMessage});
        });
    }

    isDisabled() {
		if (!this.state.selectedSelectionFilters) {
			return true;
		}
		var errors = ValidateApsResponseDownload(this.state.selectedSelectionFilters);
		if (errors?.length > 0) {
			return true;
		} 
		else {
			return false;
		}
	}
    
    render() {    
        return (
            <div className="AppFormComponent ApsResponseDownloadForm">                 
                <ApsResponseDownloadFormContext.Provider value={{
					updateSelectedSelectionFilters: this.updateSelectedSelectionFilters.bind(this), 
                    updateSelectedMySavedFilter: this.updateSelectedMySavedFilter.bind(this),
					selectedSelectionFilters: this.state.selectedSelectionFilters,
                    selectedMySavedFilter: this.state.selectedMySavedFilter,
                    mySavedFilters: this.props.mySavedFilters,
					canChangeData: this.props.canChangeData}}>
                    <form ref={ form => this.formEl = form } onSubmit={ this.handleSubmitAction }>
                        <div className="form-head">      
                            <div className="form-row downloadResponsesHeader">
					            Download Responses
				            </div>                      
                            <div className="last-data-import">
                                <h4>Last Data Import: {this.props.metadata?.lastImportDate}</h4>
                            </div>
                            <MySavedFilter 
                                mySavedFilters={this.props.mySavedFilters} 
                                handleSaveFilters={this.handleSaveFilters.bind(this)}
                                handleDeleteFilters={this.handleDeleteFilters.bind(this)} 
                                queryMySelectionFilters={this.props.queryMySelectionFilters.bind(this)}
                                updateSelectedMySavedFilter={this.updateSelectedMySavedFilter.bind(this)}
                                updateSelectedSelectionFilters={this.updateSelectedSelectionFilters.bind(this)}
                                NewMySavedFilter={NewMySavedFilter.bind(this)}
                                NewSelectionFilters={NewSelectionFilters.bind(this)}
                                selectedMySavedFilter={this.state.selectedMySavedFilter}
                                ValidateFilter={ValidateApsResponseDownload.bind(this)}
                                selectedSelectionFilters= {this.state.selectedSelectionFilters}
                            />
                            <div>                            
                                <Filters key={this.state.selectedMySavedFilter?.id} metadata={this.props.metadata}></Filters>				
                                <div className="form-row" style={{paddingTop:20}}>
                                    <div className="form-row-left">
                                        <Button disabled={this.isDisabled()} title="Download responses data as a CSV file" onClick={this.handleSubmitAction.bind(this)}>Download Data</Button>
                                    </div>					
                                </div>
                            </div>
                        </div>
                    </form>                    
                </ApsResponseDownloadFormContext.Provider>
                
                {this.state.errorMessage && ( <ShowError clearErrorMessage={() => this.setState({errorMessage: ''})} errorMessage={this.state.errorMessage} /> )}
                {this.state.successMessage && ( <ShowSuccess clearMessage={() => this.setState({successMessage: ''})} message={this.state.successMessage} /> )}                
                {this.state.deleteModalText && ( <Modal content={this.state.deleteModalText} size="small" confirmModalHandler={this.confirmDelete.bind(this)} cancelModalHandler={this.hideDeleteModal.bind(this)} />)}
            </div>            
        );
    }
}
export default withRouter(ApsResponseDownloadForm); 