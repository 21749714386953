import authHeader from './auth-header';
import Api from './api';

const API_URL = process.env.REACT_APP_AQUA_API_ENDPOINT

class ApsResponseSelectionService {
	
  async getById(responseSelectionId: number) {
	return await Api().get(`${API_URL}/ApsRespondentResponseSelection/${responseSelectionId}`, { headers:  authHeader() as any });      
  }
  async find() {
    return await Api().get(`${API_URL}/ApsRespondentResponseSelection/find`, { headers:  authHeader() as any });      
  }

  async updateResponseSelection(responseSelection: any) {
    return await Api().put(`${API_URL}/ApsRespondentResponseSelection/${responseSelection.id}`, responseSelection, 
    { method: 'PUT',
      headers:  authHeader() as any });      
  }

  async createResponseSelection(responseSelection: any) {
    return await Api().post(`${API_URL}/ApsRespondentResponseSelection`, responseSelection, 
    { method: 'POST',
      headers:  authHeader() as any });      
  }

  async deleteResponseSelection(responseSelection: any) {
    return await Api().delete(`${API_URL}/ApsRespondentResponseSelection/${responseSelection.id}`, 
    { method: 'DELETE',
      headers:  authHeader() as any });      
  }
}

export default new ApsResponseSelectionService();